#root_backend {
    @media (max-width: 1200px) {
    }
}

iframe {
    display: none;
}

.ant-layout-header.header-content {
    padding: 0 20px;
}

.row-header-menu-top {
    display: flex;
    justify-content: space-between;
}

.content-middle-main {
    @media (max-width: 1200px) {
        min-width: 1280px;
    }
}

.site-layout-background {
    @media (max-width: 1200px) {
        max-width: 100vw;
        overflow: auto;
    }
}

.box-form-login {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .title-login {
        font-size: 24px;
        text-transform: uppercase;
        text-align: center;
        margin-bottom: 30px;
    }

    .login-warp {
        width: 300px;
        margin: auto;
    }
}

.left-header-logo {
    img {
        width: 165px;
        fill: #ff0000;
        margin-left: 10px;
    }
}

.ant-menu-title-content {
    a {
        span {
            margin-right: 4px;
        }
    }
}

.row-input-order {
    border-bottom: 2px solid #4a5568;
    padding-bottom: 20px;
    margin-bottom: 20px;
}

.box-content-main-table {
    .row-table-product {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        .title-product {
            width: 125px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
    }
}

.highcharts-container {
    width: 100% !important;
}

.highcharts-root {
    width: 100% !important;
}

.row-item-user {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;
    background-color: #04bbff;

    &.active-of {
        background-color: #00000010;
    }

    .item-message {
        margin-left: 4px;
        font-weight: bold;
    }
    .item-user_name {
    }
    .item-of_user {
    }
}

.CTWYJKvghp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;

    .IjBauYpUFd {
        flex-basis: 50%;
    }
}

.list-message-user {
    max-height: 230px;
    overflow: auto;
}

.image-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.title-header-message {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: bold;
}

.row-input-send-message {
    display: flex;
}

// web pts

.PuKaWvJGmM {
}
.XkpVdamzjR {
    margin-top: 40px;
}

.kllzlteejc {
    max-height: 200px;
    overflow: auto;
}

.rkxhhhqjfo {
    width: 100%;
}

.ck-blurred {
    background-color: #ffffff;
    border: 1px solid #e1e1e1 !important;
}

.avlfkjlyda {
    margin-right: 5px;
    color: #ffffff;
}

.zcsgehvawv {
    .ant-upload-list.ant-upload-list-picture {
        display: flex;
        flex-wrap: wrap;
        .ant-upload-list-item-name {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            width: 100px;
        }
    }
}

.qisjkllnax {
    background-color: #4a556820;
    margin-bottom: 8px;
    padding: 4px;
}

.jgujaaaomu {
    font-size: 28px;
    margin-right: 6px;
}

.uzjbfcukks {
    display: flex;
    justify-content: space-between;
}

iframe {
    display: none;
}

.ioogqnyxhj {
    display: flex;
    overflow: auto;

    .pgxnslbqhg {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        font-weight: bold;
        height: 40px;
        min-width: 200px;
        margin-right: 4px;
        transition: 0.6s all;
        cursor: pointer;
        word-break: break-all;
        text-align: center;
        position: relative;

        &.twbifjlqvc {
            color: #ffffff;
            background-color: #e08383;
        }

        &:hover {
            color: #000000;
        }

        .tfrftoubdd {
            position: absolute;
            top: 0;
            right: 0;
            width: 20px;
            height: 20px;
            background-color: #e08383;
            border-radius: 50%;
        }
    }
}

.doifyprdhs {
    background-color: #00000010;
    padding: 8px;
    padding-bottom: 120px;
}

.qshmfigade {
    font-weight: bold;
}

.vowvrqnxli {
    margin-bottom: 8px;
}

.nlhrorilkm {
    width: 100%;
    tr {
        td {
            height: 40px;
            border: 1px solid #000000;
        }
    }
}

.bmuncytwmh {
    width: 100%;
}

.uzjbfcukks {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dibwpioxhv {
    text-decoration: line-through;
}

.esiqveqlal {
    display: flex;
    flex-wrap: wrap;
    padding: 6px 5px;
    margin-bottom: 10px;
}

.AMIaAVtBqX {
    position: relative;

    .CsjgxirKgP {
        position: absolute;
        top: 0;
        right: 0;
        transform: translateX(50%) translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #afafaf;
        font-size: 16px;
        color: #cc4a4a;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
}

.wtcygdslpc {
    cursor: pointer;
    white-space: pre-line;

    &:hover {
        background-color: #e0838320;
    }
}

.amitupwtjq {
    position: relative;

    .mxpefrcshf {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 18px;
        color: #15a52d;
    }
}

.QBSAiqWULo {
    font-size: 24px;
    font-weight: bold;
    background-color: #ffffff;
    padding: 12px 0 12px 12px;
    margin-bottom: 12px;
}

@media print {
    .pagebreak {
        page-break-before: always;
    }
}

.qiAJkMhfXE {
    width: 700px;
    margin: auto;
}

.NuHleMZhmL {
    border: 1px solid #000000;
}

.pJRmDMWYKi {
    text-align: right;
}

.zuMqFcMdgb {
    text-align: center;
}
