@page {
  size: 7in 9.25in;
  margin: 27mm 16mm 27mm 16mm;
}

@media print {
  html,
  body {
    width: 210mm;
    height: 297mm;
  }
}

// #table-to-xls {
//   width: 500px;
// }
